import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Content from '../components/Content';

const WorkStyles = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  h2 {
    font-family: Futura, sans-serif;
    font-weight: normal;
    text-transform: lowercase;
    text-align: center;
    font-size: 30px;
    margin: 0 0 0.6em;
  }

  h3 {
    margin: 0 0 6px;
    font-family: Futura, sans-serif;
    font-size: 1.3rem;
    font-weight: 300;

    a {
      text-decoration: none;
    }
  }

  @media (min-width: 1340px) {
    h3 {
      font-size: 1.5rem;
    }
  }

  .projects {
    display: grid;
    grid-template-columns: 1;
    column-gap: 30px;
    row-gap: 50px;
    justify-content: space-betwen;
  }

  @media (min-width: 900px) {
    .projects {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1280px) {
    .projects {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .project {
    position: relative;
  }

  @media (min-width: 900px) {
    .project {
      padding: var(--padd);
    }
  }

  .image-container,
  .project-features {
    margin: 0 0 1rem;
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: var(--red);
    }
  }

  .project-client,
  .project-agency {
    font-size: 1.1rem;
    line-height: 1.4;
  }
`;

export default function Work({ data }) {
  const projects = data.folio.nodes[0].selected_projects;
  return (
    <Layout page="Work">
      <Content>
        <WorkStyles>
          <div className="projects">
            {projects.map((project) => {
              const values = {
                images: project.project_image.length,
                features: project.featured.length,
              };

              return (
                <div className="project" key={project.slug.current}>
                  {values.images > 0 &&
                    project.project_image.map((image) => {
                      return (
                        <div key={image.asset} className="image-container">
                          <Link to={project.slug.current}>
                            <GatsbyImage
                              image={getImage(image.asset)}
                              alt="Kate Langham"
                            />
                          </Link>
                        </div>
                      );
                    })}
                  <h3>
                    <Link to={project.slug.current}>{project.title}</Link>
                  </h3>
                  <p className="project-client">
                    <b>Client: </b>
                    {project.client}
                  </p>
                  <p className="project-agency">
                    <b>Agency: </b>
                    {project.agency}
                  </p>
                </div>
              );
            })}
          </div>
        </WorkStyles>
      </Content>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    folio: allSanityFolio {
      nodes {
        selected_projects {
          agency
          client
          description
          slug {
            current
          }
          title
          project_image {
            asset {
              gatsbyImageData(width: 800, height: 450)
            }
          }
          featured {
            feature_link
            feature_title
          }
        }
      }
    }
  }
`;
